/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-key */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable quotes */
import React, { useState, useEffect } from 'react';
import { collection, getDocs, deleteDoc } from 'firebase/firestore';
import { db } from '../utils/firebase';
const CleanGames = () => {
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const cleanGeneratedGames = async () => {
            const currentDate = new Date();
            const day = currentDate.getDate();
            const month = currentDate.getMonth() + 1; // Month starts from 0
            const year = currentDate.getFullYear();

            // Get the number of days in the current month
            const daysInMonth = new Date(year, month, 0).getDate();

            for (let i = day; i <= daysInMonth; i++) {
                const dayString = i.toString().padStart(2, '0');
                const monthString = month.toString().padStart(2, '0');
                const dateString = `${dayString}-${monthString}-${year}`;

                const collectionRef = collection(db, dateString);
                const docSnap = await getDocs(collectionRef);

                if (!docSnap.empty) {
                    // Delete each document in the collection
                    docSnap.forEach(async (doc) => {
                        await deleteDoc(doc.ref);
                        console.log(`Deleted document with ID: ${doc.id} for date: ${dateString}`);
                    });
                    console.log(`All documents deleted for ${dateString}`);
                } else {
                    console.log(`No documents found for ${dateString}`);
                }
            }
        };

        const executeCleaning = async () => {
            try {
                await cleanGeneratedGames();
                setStatus('Games cleaned successfully');
            } catch (error) {
                setStatus(`Error cleaning games: ${error.message}`);
            } finally {
                setLoading(false);
            }
        };

        executeCleaning();
    }, []);

    return (
        <div>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <p>{status}</p>
            )}
        </div>
    );
};

export default CleanGames;
