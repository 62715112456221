/* eslint-disable indent */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";

const SignOut = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const auth = getAuth();
        signOut(auth).then(() => {
            // Redirect to home or login page after sign-out
            navigate("/login");
        }).catch((error) => {
            // Handle errors here
            console.error("Sign out error", error);
        });
    }, [navigate]);

    return (
        <div>
            Signing you out...
        </div>
    );
};

export default SignOut;
