/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-key */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable quotes */import React, { useEffect, useState } from 'react';
import UserList from '../../Components/userList';
import ModalComponent from '../../Components/modal';
import { auth, db } from '../../utils/firebase';
import { collection, query, where, addDoc, onSnapshot, getDocs } from 'firebase/firestore';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, updateProfile } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const UserPanelMaster = () => {
    const navigate = useNavigate();

    const [addUser, setAddUser] = useState(false);
    const [userArr, setUserArr] = useState([]);
    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [type, setType] = useState('User');
    const [percentage, setPercentage] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState('');
    const [statusFilter, setStatusFilter] = useState('active'); // New state for status filter

    const handleInputChange = (e, setState) => {
        setState(e.target.value);
    };

    useEffect(() => {
        const fetchData = () => {
            try {
                // Query for fetching user profiles associated with the admin email
                const q = query(collection(db, "userProfile"), where("masterEmail", "==", auth.currentUser.email));

                // Listen for real-time updates
                const unsubscribe = onSnapshot(q, (usersSnapshot) => {
                    const usersDataArray = usersSnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }));

                    // Filter user list based on search query and status filter
                    const filteredUsers = usersDataArray.filter(user =>
                        ((user.displayName && user.displayName.toLowerCase().includes(searchQuery.toLowerCase())) ||
                            (user.emailId && user.emailId.toLowerCase().includes(searchQuery.toLowerCase())) ||
                            (user.phoneNumber && user.phoneNumber.toLowerCase().includes(searchQuery.toLowerCase()))) &&
                        (statusFilter === 'all' || user.status === statusFilter)
                    );

                    setUserArr(filteredUsers);
                });

                return () => unsubscribe();
            } catch (error) {
                console.error("Error fetching documents: ", error);
            }
        };

        fetchData();
    }, [searchQuery, statusFilter]); // Include statusFilter in dependency array

    const addUserFunction = async () => {
        if (!name || !email || !password || !type) {
            alert("All fields except percentage must be filled.");
            return;
        }

        try {
            const emailAuth = auth.currentUser.email;
            const formattedEmail = `${email.toLowerCase()}@gmail.com`;

            const q = query(collection(db, "userProfile"), where("emailId", "==", emailAuth));
            let adminEmailLog;

            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                adminEmailLog = doc.data().adminEmail
            });

            // Check if username already exists
            const usernameQuery = query(collection(db, "userProfile"), where("emailId", "==", formattedEmail));
            const usernameQuerySnapshot = await getDocs(usernameQuery);
            if (!usernameQuerySnapshot.empty) {
                console.log("Username already exists. Operation cancelled.");
                return;
            }

            // Create the new user
            const userCredential = await createUserWithEmailAndPassword(auth, formattedEmail, password);
            const user = userCredential.user;

            // Update the displayName in Firebase Authentication
            await updateProfile(user, { displayName: name });

            // Add the new user to Firestore with the correct uid and other details
            await addDoc(collection(db, "userProfile"), {
                uid: user.uid, // Use the created user's uid
                displayName: name,
                username: username, // Add the username to the Firestore document
                emailId: formattedEmail,
                type: type,
                percentage: 0,
                phoneNumber: phoneNumber,
                wallet: 0,
                accessCode: '',
                fundAddHistory: [],
                fundWithdrawHistory: [],
                betLog: [],
                winLog: [],
                adminEmail: adminEmailLog,
                passwordStatus: false,
                masterEmail: emailAuth,
                status: 'active',
                password: password,
                logAdminEmail: adminEmailLog
            });
            console.log("User added successfully");

            // Sign out the newly created user
            await signOut(auth);

            // Clear input fields after adding user
            setName('');
            setUsername('');
            setPhoneNumber('');
            setType('');
            setPercentage('');
            setPassword('');

            // Close the modal
            setAddUser(false);

            // Refresh user list
            setSearchQuery('');

            // Log back in using stored credentials
            const storedEmail = localStorage.getItem('email');
            const storedPassword = localStorage.getItem('password');

            if (storedEmail && storedPassword) {
                await signInWithEmailAndPassword(auth, storedEmail, storedPassword);
                console.log("Re-logged in successfully");

                // Navigate to users page
                navigate('/users');
            } else {
                console.error("Stored credentials not found.");
            }
        } catch (error) {
            console.error("Error adding user: ", error);
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleStatusFilterChange = (e) => {
        setStatusFilter(e.target.value);
    };

    return (
        <div>
            {addUser &&
                <ModalComponent closeModal={() => setAddUser(false)}>
                    <div className='addUserModal' style={{ flexDirection: 'column', display: "flex" }}>
                        <p className='addUserModalHeading'>Add User</p>
                        <div className="info-Inputs-user">
                            <label htmlFor="name">
                                Name
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder='Enter Name'
                                    value={name}
                                    onChange={(e) => handleInputChange(e, setName)}
                                />
                            </label>
                            <label htmlFor="emailid">
                                Username
                                <input
                                    type="text"
                                    id="email"
                                    name="emailid"
                                    placeholder='Enter Username'
                                    value={email}
                                    onChange={(e) => handleInputChange(e, setEmail)}
                                />
                            </label>
                            <label htmlFor="phoneNumber">
                                Phone Number
                                <input
                                    type="number"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    placeholder='Enter Phone Number'
                                    value={phoneNumber}
                                    onChange={(e) => handleInputChange(e, setPhoneNumber)}
                                />
                            </label>

                            <label htmlFor="percentage">
                                Percentage
                                <input
                                    type="text"
                                    id="percentage"
                                    name="percentage"
                                    placeholder='Enter Percentage'
                                    value={percentage}
                                    onChange={(e) => handleInputChange(e, setPercentage)}
                                />
                            </label>
                            <label htmlFor="password">
                                Password
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    placeholder='Enter Password'
                                    value={password}
                                    onChange={(e) => handleInputChange(e, setPassword)}
                                />
                            </label>
                            <button className='addUserSubmit' onClick={addUserFunction}>Add User</button>
                        </div>
                    </div>
                </ModalComponent>
            }
            <div className="toppart-diff">
                <div className="section--29485">
                    <input type="text" placeholder='Search by Name' onChange={handleSearchChange} />
                    <input type="text" placeholder='Search by Username' onChange={handleSearchChange} />
                    <input type="text" placeholder='Search by Phone Number' onChange={handleSearchChange} />
                    <select onChange={handleStatusFilterChange}>
                        <option value="">Select an Option</option>
                        <option value="all">All</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                    </select>
                </div>
                <div className="section-553872">
                    <button id='search-userPanel' onClick={() => setSearchQuery(searchQuery)}>Search</button>
                    <button id='addUser-usePanel' onClick={() => setAddUser(true)}>Add User</button>
                </div>
            </div>
            <div className="v-Heading-UserList">
                <p>User List</p>
            </div>
            <UserList users={userArr} />
        </div>
    );
};

export default UserPanelMaster;
