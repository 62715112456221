/* eslint-disable indent *//* eslint-disable linebreak-style */
/* eslint-disable quotes */
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyCfElC4faSrkwJZq8eeCgXzII9qjGT1JfU",
    authDomain: "fatafatdaily-3b7bb.firebaseapp.com",
    projectId: "fatafatdaily-3b7bb",
    storageBucket: "fatafatdaily-3b7bb.appspot.com",
    messagingSenderId: "752748271655",
    appId: "1:752748271655:web:3f09ca5832386ae9545602"
};  

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);
