/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-key */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable quotes */

import React from 'react'
import Navbar from '../Components/navbarSuperAdmin'

const NotFound = () => {
    return (
        <div>
            <Navbar />
            <h1 style={{
                width: '100%', height: '100vh', textAlign: 'center'
            }}>404 Not Found</h1>
        </div>
    )
}

export default NotFound
