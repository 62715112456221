/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-key */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable quotes */import React, { useEffect, useState, useCallback } from 'react';
import { getDocs, collection, query, where } from 'firebase/firestore';
import { db, auth } from '../../utils/firebase';
import moment from 'moment';

const ProfitLossAdmin = () => {
    const [profitLossData, setProfitLossData] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [formattedData, setFormattedData] = useState([]);
    const [extendedData, setExtendedData] = useState([]);

    const formatDate = (date) => {
        const d = new Date(date);
        const day = d.getDate().toString().padStart(2, '0');
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const year = d.getFullYear();
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        const today = new Date();
        const formattedStartDate = formatDate(today);
        const formattedEndDate = formatDate(today);
        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);

        const loadData = async () => {
            const userProfileMap = await fetchUserEmails();
            await fetchBetsForUsers(userProfileMap);
        };

        loadData();
    }, []);

    const fetchUserEmails = useCallback(async () => {
        const userProfileCollectionRef = query(collection(db, 'userProfile'), where('adminEmail', '==', auth.currentUser.email));
        const userProfileQuerySnapshot = await getDocs(userProfileCollectionRef);

        const userProfileMap = {};

        userProfileQuerySnapshot.forEach((doc) => {
            const userData = doc.data();
            userProfileMap[userData.emailId] = userData.type;
        });

        return userProfileMap;
    }, []);

    const fetchBetsForUsers = useCallback(async (userProfileMap) => {
        // Step 1: Fetch bets where 'adminEmail' matches the current user's email
        const betsCollectionRef = query(collection(db, 'bets'), where('adminEmail', '==', auth.currentUser.email));
        const betsQuerySnapshot = await getDocs(betsCollectionRef);

        // Step 2: Extract 'emailIds' and store them in an array
        const emailIds = [];
        const betInstant = [];
        betsQuerySnapshot.forEach((doc) => {
            const betData = doc.data();
            betInstant.push(betData);
            if (betData.user) {
                emailIds.push(betData.user);
            }
        });
        console.log(betInstant);

        // Step 3: Fetch bets where 'masterEmail' matches any 'emailId'
        let masterBetsQuerySnapshot = { docs: [] };

        if (emailIds.length > 0) {
            const masterBetsPromises = emailIds.map(email => {
                const masterEmailQuery = query(collection(db, 'bets'), where('masterEmail', '==', email));
                return getDocs(masterEmailQuery);
            });

            const masterBetsSnapshots = await Promise.all(masterBetsPromises);
            masterBetsSnapshots.forEach(snapshot => {
                masterBetsQuerySnapshot.docs.push(...snapshot.docs);
            });
        }

        // Step 4: Combine both snapshots
        const combinedSnapshots = [...betsQuerySnapshot.docs, ...masterBetsQuerySnapshot.docs];
        // console.log(combinedSnapshots);

        // Step 5: Initialize maps to store data
        const emailMap = {};
        const masterBetsMap = {};
        const adminBetsMap = {};

        combinedSnapshots.forEach((doc) => {
            const betData = doc.data();
            const { user: userEmail, masterEmail, adminEmail, amount, number, status } = betData;
            const date = moment(betData.date, 'DD/MM/YYYY');
            const start = moment(startDate);
            const end = moment(endDate);

            if (date.isBetween(start, end, undefined, '[]')) {
                if (userEmail) {
                    const userType = userProfileMap[userEmail];
                    const key = `${userEmail}_${userType}`;

                    if (!emailMap[key]) {
                        emailMap[key] = {
                            email: userEmail,
                            type: "User",
                            amountPlayed: 0,
                            amountWon: 0,
                            profitLoss: 0, masterEmail,
                            adminEmail
                        };
                    }

                    let amountWon = 0;
                    if (status === 'win') {
                        if (number.toString().length === 1) {
                            amountWon = amount * 9;
                        } else if (number.toString().length === 3) {
                            amountWon = amount * 100;
                        }
                    }

                    emailMap[key].amountPlayed += amount;
                    emailMap[key].amountWon += amountWon;

                    if (masterEmail) {
                        if (!masterBetsMap[masterEmail]) {
                            masterBetsMap[masterEmail] = {
                                email: masterEmail,
                                type: 'Master',
                                amountPlayed: 0,
                                amountWon: 0,
                                profitLoss: 0, masterEmail,
                                adminEmail
                            };
                        }
                        masterBetsMap[masterEmail].amountPlayed += amount;
                        masterBetsMap[masterEmail].amountWon += amountWon;
                    }

                    if (adminEmail) {
                        if (!adminBetsMap[adminEmail]) {
                            adminBetsMap[adminEmail] = {
                                email: adminEmail,
                                type: 'Admin',
                                amountPlayed: 0,
                                amountWon: 0,
                                profitLoss: 0, masterEmail,
                                adminEmail
                            };
                        }
                        adminBetsMap[adminEmail].amountPlayed += amount;
                        adminBetsMap[adminEmail].amountWon += amountWon;
                    }
                }
            }
        });

        // Step 6: Calculate profit/loss
        Object.values(emailMap).forEach(entry => {
            entry.profitLoss = entry.amountPlayed - entry.amountWon;
        });

        Object.values(masterBetsMap).forEach(entry => {
            entry.profitLoss = entry.amountPlayed - entry.amountWon;
        });

        Object.values(adminBetsMap).forEach(entry => {
            entry.profitLoss = entry.amountPlayed - entry.amountWon;
        });

        // Step 7: Combine all data and update state
        const formattedData = [
            ...Object.values(emailMap),
            ...Object.values(masterBetsMap),
            ...Object.values(adminBetsMap),
        ];

        setFormattedData(formattedData);
        setProfitLossData(formattedData);
    }, [startDate, endDate]);


    const handleSearch = () => {
        if (startDate && endDate) {
            const loadData = async () => {
                const userProfileMap = await fetchUserEmails();
                await fetchBetsForUsers(userProfileMap);
            };

            loadData();
        }
    };

    const handleSearchTermChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleExtendData = async (emailid, typeid) => {
        const userProfileCollectionRef = collection(db, 'userProfile');
        const userProfileQuerySnapshot = await getDocs(userProfileCollectionRef);

        const emails = [];

        userProfileQuerySnapshot.forEach((doc) => {
            const userData = doc.data();

            if (typeid === 'Master' && userData.masterEmail === emailid) {
                emails.push(userData.emailId);
            } else if (typeid === 'Admin' && userData.adminEmail === emailid) {
                emails.push(userData.emailId);
            }
        });

        const extendedData = formattedData
            .filter((entry) => emails.includes(entry.email))
            .reduce((acc, entry) => {
                if (!acc[entry.email]) {
                    acc[entry.email] = { ...entry };
                } else {
                    acc[entry.email].amountPlayed += entry.amountPlayed;
                    acc[entry.email].amountWon += entry.amountWon;
                    acc[entry.email].profitLoss += entry.profitLoss;
                }
                return acc;
            }, {});

        const extendedDataArray = Object.values(extendedData);
        setExtendedData(extendedDataArray);
    };

    const filteredData = formattedData.filter((entry) =>
        entry.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    console.log(filteredData);

    // Filter data for only 'User' type
    const userFilteredData = filteredData.filter((entry) => entry.type === 'User');

    // Calculate totals for the initial table
    const totalUserAmountPlayed = userFilteredData.reduce((total, entry) => total + entry.amountPlayed, 0);
    const totalUserAmountWon = userFilteredData.reduce((total, entry) => total + entry.amountWon, 0);
    const totalUserProfitLoss = userFilteredData.reduce((total, entry) => total + entry.profitLoss, 0);

    // Calculate totals for the extended table
    const totalExtendedAmountPlayed = extendedData.reduce((total, entry) => total + entry.amountPlayed, 0);
    const totalExtendedAmountWon = extendedData.reduce((total, entry) => total + entry.amountWon, 0);
    const totalExtendedProfitLoss = extendedData.reduce((total, entry) => total + entry.profitLoss, 0);

    return (
        <div>
            <div className="toppart-diff">
                <div className="section--294851">
                    <label htmlFor="start-date">
                        <p>From Date</p>
                        <input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </label>
                    <label htmlFor="end-date">
                        <p>To Date</p>
                        <input
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </label>
                </div>
                <div className="section-5538721">
                    <button
                        className="submitSearchQueryProfitLoss"
                        onClick={handleSearch}
                    >
                        Search by Date
                    </button>
                </div>
            </div>
            <div className="table-wrapper">
                <input
                    type="text"
                    placeholder="Search by Username"
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                />
                <table className="fl-table">
                    <thead>
                        <tr>
                            <th>Username</th>
                            <th>Type</th>
                            <th>Total Played</th>
                            <th>Total Won</th>
                            <th>P/L</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData
                            .filter((entry) => entry.type === 'Master' || entry.masterEmail === '')
                            .map((entry, index) => (
                                <tr key={index}>
                                    <td style={{ cursor: "pointer" }} onClick={() => handleExtendData(entry.email, entry.type)}>
                                        {entry.email.replace("@gmail.com", "")}
                                    </td>
                                    <td>{entry.type}</td>
                                    <td>{entry.amountPlayed}</td>
                                    <td>{entry.amountWon}</td>
                                    <td>{entry.profitLoss}</td>
                                </tr>
                            ))}


                        <tr>
                            <td colSpan="2"><strong>User Totals</strong></td>
                            <td><strong>{totalUserAmountPlayed}</strong></td>
                            <td><strong>{totalUserAmountWon}</strong></td>
                            <td><strong>{totalUserProfitLoss}</strong></td>
                        </tr>
                    </tbody>
                </table>
                {extendedData.length > 0 && (
                    <div className="extended-table-wrapper">
                        <h3 style={{ textAlign: 'center', margin: '10px' }}>Extended Data</h3>
                        <table className="fl-table">
                            <thead>
                                <tr>
                                    <th>Username</th>
                                    <th>Type</th>
                                    <th>Total Played</th>
                                    <th>Total Won</th>
                                    <th>P/L</th>
                                </tr>
                            </thead>
                            <tbody>
                                {extendedData.map((entry, index) => (
                                    <tr key={index}>
                                        <td>{entry.email.split('@')[0]}</td>
                                        <td>{entry.type}</td>
                                        <td>{entry.amountPlayed}</td>
                                        <td>{entry.amountWon}</td>
                                        <td>{entry.profitLoss}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td colSpan="2"><strong>Totals</strong></td>
                                    <td><strong>{totalExtendedAmountPlayed}</strong></td>
                                    <td><strong>{totalExtendedAmountWon}</strong></td>
                                    <td><strong>{totalExtendedProfitLoss}</strong></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProfitLossAdmin;