/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable quotes */import React from 'react'
import './loading.css'

const Loading = () => {
    return (
        <div className='loading'>
            <div className="loading-container">
                <div className="loading-text">
                    <span>P</span>
                    <span>R</span>
                    <span>O</span>
                    <span>T</span>
                    <span>E</span>
                    <span>C</span>
                    <span>T</span>
                    <span>E</span>
                    <span>D</span>

                </div>
            </div>
        </div>
    )
}

export default Loading
