/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-key */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable quotes */import React, { useEffect, useState } from "react";
import "./../route.css";
import { CiClock2 } from "react-icons/ci";
import { TfiCup } from "react-icons/tfi";
import { db } from "../../utils/firebase";
import { where, query } from "firebase/firestore";
import { addDoc, collection, getDocs, doc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";

const GameAdmin = () => {
  const currentDate = new Date();
  const day = currentDate.getDate().toString().padStart(2, '0');
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const year = currentDate.getFullYear();
  const dateString = `${day}-${month}-${year}`;

  const [modalStatus, setModalStatus] = useState(false)
  const [modalResult, setModalResult] = useState(false)
  const [indexValue, setIndexValue] = useState()
  const [gameState, setGameState] = useState()
  const [gameID, setGameID] = useState()
  const [selectedStatus, setSelectedStatus] = useState("")
  const [selectedNumber, setSelectedNumber] = useState("");
  const [singleResult, setSingleResult] = useState("");
  const [selectedDate, setSelectedDate] = useState(dateString);
  const [currentResult, setCurrentResult] = useState()
  const [bets, setBets] = useState([])
  const [currentTResult, setCurrentTResult] = useState()
  const [combinedData, setCombinedData] = useState([]);






  useEffect(() => {
    const fetchData = async () => {
      const dateString = selectedDate;

      const querySnapshot = await getDocs(collection(db, dateString));
      const allData = [];
      querySnapshot.forEach((doc) => {
        allData.push({ id: doc.id, ...doc.data() });
      });

      const validData = allData.filter(item => item.gameName);
      validData.sort((a, b) => a.gameName.localeCompare(b.gameName));

      setGameState(validData);
    };
    fetchData();
  }, [selectedDate]);

  const handleSearch = () => {
    const selectedDateObject = new Date(selectedDate); // Convert selectedDate string to Date object
    const day = selectedDateObject.getDate().toString().padStart(2, '0'); // Get day
    const month = (selectedDateObject.getMonth() + 1).toString().padStart(2, '0'); // Get month
    const year = selectedDateObject.getFullYear(); // Get year
    const selectedDateString = `${day}-${month}-${year}`; // Construct the date string in "dd-mm-yyyy" format
    setSelectedDate(selectedDateString); // Update selectedDate state
  };








  const handleStatusSubmit = async () => {
    if (selectedStatus === "") {
      console.log("Please select a status");
      return;
    }

    if (!gameID) {
      console.log("Game ID is not defined");
      return;
    }

    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Note: Month starts from 0
    const year = currentDate.getFullYear();
    const dateString = `${day}-${month}-${year}`;

    const docRef = doc(db, dateString, gameID);
    try {
      await updateDoc(docRef, {
        gameStatus: selectedStatus
      });
      console.log(`Game status updated to ${selectedStatus}`);
      setModalStatus(false);
    } catch (error) {
      console.error("Error updating game status:", error);
    }
    setModalStatus(false)
  };



  return (
    <div className="game--wrapper">


      <div className="toppart-diff">
        <div className="section--29485 gameJsx">
          <p className="nameDisplay-002">Kolkata Fatafat</p>
          <input type="date" name="date" id="dateGameWrrapper" value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)} />
          <button className="search-Btn-00239" onClick={handleSearch}>Search</button>
        </div>

      </div>
      {gameState && gameState.length > 0 ? (<div className="baziWrapper">
        {
          gameState && gameState.map((item, key) => (
            <div className="baziChilds">
              <div className="baziDetails">
                <div className="baziName" style={{ marginBottom: '10px' }}>{item.gameName}</div>
                <div className="gameTime"><CiClock2 /> Game Time : {item.gameTimeStart} to {item.gameTimeEnd}</div>
                <div className="baziNumbers"><TfiCup />Results : {item.result} | {item.singleResult} | {item.id}</div>
              </div>

            </div>
          ))
        }


      </div>) : (<div> NO DATA</div>)}

    </div >
  );
};

export default GameAdmin;
