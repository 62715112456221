/* eslint-disable linebreak-style */
/* eslint-disable indent */
/* eslint-disable quotes */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../utils/firebase';
import { onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';
import './login.css';

const LoginPage = () => {
    const [credentials, setCredentials] = useState({ email: '', password: '' });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                navigate('/');
            }
        });
    }, [navigate]);

    const handleChange = (e) => {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            let email = credentials.email;
            if (!email.includes('@gmail.com')) {
                email = `${email}@gmail.com`;
            }

            await signInWithEmailAndPassword(auth, email, credentials.password);

            // Remove previous localStorage data
            localStorage.removeItem('email');
            localStorage.removeItem('password');

            // Store email and password in localStorage
            localStorage.setItem('email', email);
            localStorage.setItem('password', credentials.password);

            navigate('/'); // Redirect to home or dashboard after successful login
        } catch (error) {
            setError('Invalid email or password. Please try again.');
            console.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='LoginPage'>
            <div className="login-opage-component">
                <h1>Login to Your Account</h1>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>Email:</label>
                        <input
                            type="text"
                            name="email"
                            value={credentials.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label>Password:</label>
                        <input
                            type="password"
                            name="password"
                            value={credentials.password}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    {error && <div className="error-message">{error}</div>}
                    <button type="submit" className='loginButton' disabled={loading}>
                        {loading ? 'Logging in...' : 'Login'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default LoginPage;
