/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable quotes */import React, { useState, useEffect } from 'react';
import { db } from '../utils/firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import './table.css';
import { useParams } from 'react-router-dom';
import AdditionalInnerUser2 from './additionalInnerUser2';

const AdditionalInnerUser = ({ username, type }) => {
    const [selectedUserInfo, setSelectedUserInfo] = useState(null); // Initialize as null
    const [users, setUsers] = useState([]);
    const [message, setMessage] = useState('');

    // useEffect to fetch users based on username and type
    useEffect(() => {
        const fetchUsers = async () => {
            if (type === 'User') {
                setMessage('THIS IS A USER');
                setUsers([]);
                return;
            }

            let q;
            if (type === 'Admin') {
                q = query(collection(db, 'userProfile'), where('adminEmail', '==', username));
            } else if (type === 'Master') {
                q = query(collection(db, 'userProfile'), where('masterEmail', '==', username));
            }

            try {
                const querySnapshot = await getDocs(q);
                const usersList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setUsers(usersList);
                setMessage('');
            } catch (error) {
                console.error("Error fetching users:", error);
                setMessage('Error fetching users');
            }
        };

        fetchUsers();
    }, [username, type]); // Trigger fetchUsers whenever username or type changes

    // Handle click on username
    const handleUsernameClick = (usernameU, typeu) => {
        setSelectedUserInfo({ username: usernameU, type: typeu });
    };

    // useEffect to reset selectedUserInfo to null when username or type changes
    useEffect(() => {
        setSelectedUserInfo(null);
    }, [username, type]);

    return (
        <>
            <div>
                <h1>YOU ARE VIEWING {username}</h1>
                {message && <h1>{message}</h1>}
                {users.length > 0 && (
                    <div className="table-wrapper">
                        <table className="fl-table" style={{ borderCollapse: 'separate' }}>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Username</th>
                                    <th>Type</th>
                                    <th>Phone Number</th>
                                    <th>%</th>
                                    <th>Balance</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((user, index) => (
                                    <tr key={index}>
                                        <td data-label="Name">{user.displayName}</td>
                                        <td style={{ border: '1px solid #000', cursor: 'pointer' }} data-label="Username" onClick={() => handleUsernameClick(user.emailId, user.type)}>
                                            {user.emailId}
                                        </td>
                                        <td data-label="Type">{user.type}</td>
                                        <td data-label="Phone Number">{user.phoneNumber}</td>
                                        <td data-label="%">{user.percentage}</td>
                                        <td data-label="Balance">{user.wallet}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
            {selectedUserInfo && <AdditionalInnerUser2 username={selectedUserInfo.username} type={selectedUserInfo.type} />}
        </>
    );
};

export default AdditionalInnerUser;
