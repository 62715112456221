/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-key */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable quotes */

// import React, { useState } from 'react';
// import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
// import { db } from '../utils/firebase';

// const CleanWithdrawHistory = () => {
//     const [loading, setLoading] = useState(false);
//     const [message, setMessage] = useState('');

//     const cleanHistory = async () => {
//         setLoading(true);
//         setMessage('');

//         try {
//             const userProfileSnapshot = await getDocs(collection(db, 'userProfile'));

//             for (const userDoc of userProfileSnapshot.docs) {
//                 const userData = userDoc.data();
//                 const fundWithdrawHistory = userData.fundWithdrawHistory || [];
//                 const filteredHistory = fundWithdrawHistory.filter(entry => entry.status !== null && entry.status !== undefined);

//                 if (filteredHistory.length !== fundWithdrawHistory.length) {
//                     const userProfileRef = doc(db, 'userProfile', userDoc.id);
//                     await updateDoc(userProfileRef, { fundWithdrawHistory: filteredHistory });
//                 }
//             }

//             setMessage('Withdraw history cleaned successfully.');
//         } catch (error) {
//             console.error('Error cleaning withdraw history:', error);
//             setMessage('Error cleaning withdraw history.');
//         }

//         setLoading(false);
//     };

//     return (
//         <div className="clean-history-container">
//             <h1>Clean Withdraw History</h1>
//             <button onClick={cleanHistory} disabled={loading}>
//                 {loading ? 'Cleaning...' : 'Clean History'}
//             </button>
//             {message && <p>{message}</p>}
//         </div>
//     );
// };

// export default CleanWithdrawHistory;


// FOR ADD HISTORY 
import React, { useState } from 'react';
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from '../utils/firebase';

const CleanAddHistory = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');

    const cleanHistory = async () => {
        setIsLoading(true);
        setMessage('');
        try {
            const userProfilesSnapshot = await getDocs(collection(db, "userProfile"));

            const updatePromises = userProfilesSnapshot.docs.map(async (userProfileDoc) => {
                const userProfileData = userProfileDoc.data();
                const fundAddHistory = userProfileData.fundAddHistory || [];

                const filteredHistory = fundAddHistory.filter(entry => entry.status !== null && entry.status !== undefined);

                if (fundAddHistory.length !== filteredHistory.length) {
                    const userProfileRef = doc(db, "userProfile", userProfileDoc.id);
                    await updateDoc(userProfileRef, { fundAddHistory: filteredHistory });
                }
            });

            await Promise.all(updatePromises);
            setMessage('Clean-up completed successfully.');
        } catch (error) {
            console.error('Error cleaning add history:', error);
            setMessage('Error cleaning add history.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <h1>Clean Fund Add History</h1>
            <button onClick={cleanHistory} disabled={isLoading}>
                {isLoading ? 'Cleaning...' : 'Clean History'}
            </button>
            {message && <p>{message}</p>}
        </div>
    );
};

export default CleanAddHistory;
