/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable quotes */
import React, { useEffect } from 'react';
import { getDocs, collection, addDoc } from 'firebase/firestore';
import { db } from '../utils/firebase';

const GenerateGame = () => {
    const game = [
        { gameName: '1', result: null, singleResult: null, declared: false, fundTransferedTriple: false, fundTransferedSingle: false, gameTimeStart: '7:30', gameTimeEnd: '10:00', gameStatus: 'Upcoming', bets: [{ number: '', betBy: '', amount: '', betTime: '' }], gameDate: '', totalBets: '', totalAmount: '', },
        { gameName: '2', result: null, singleResult: null, declared: false, fundTransferedTriple: false, fundTransferedSingle: false, gameTimeStart: '10:30', gameTimeEnd: '12:00', gameStatus: 'Upcoming', bets: [{ number: '', betBy: '', amount: '', betTime: '' }], numbersArr: [{ number: '100', bets: '0', totalAmount: '0' }], gameDate: '', totalBets: '', totalAmount: '', },
        { gameName: '3', result: null, singleResult: null, declared: false, fundTransferedTriple: false, fundTransferedSingle: false, gameTimeStart: '12:30', gameTimeEnd: '02:00', gameStatus: 'Upcoming', bets: [{ number: '', betBy: '', amount: '', betTime: '' }], gameDate: '', totalBets: '', totalAmount: '', win: '', loss: '' }];


    const generateGameFunc = async () => {
        const currentDate = new Date();
        const day = currentDate.getDate().toString().padStart(2, '0');
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Note: Month starts from 0
        const year = currentDate.getFullYear();
        const dateString = `${day}-${month}-${year}`;



        const docSnap = await getDocs(collection(db, dateString));

        if (docSnap.empty) {
            // Collection contains documents
            docSnap.forEach((doc) => {
                console.log("Document data:", doc.data());
            });
            console.log('Document Not Found');
            // Loop through each game object and add it as a document to Firestore
            for (const singleGame of game) {
                const docRef = await addDoc(collection(db, dateString), singleGame);
                console.log("Document written with ID: ", docRef.id);
            }
        } else {
            // Collection is empty
            console.log("Document Found.");
        }

        const docRef = await addDoc(collection(db, 'text'), game);
    };
    useEffect(() => {
        generateGameFunc();
    });
    return (
        <div>

        </div>
    );
};

export default GenerateGame;
