/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-key */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable quotes */import React, { useEffect, useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import './navbar.css'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import { auth, db } from '../utils/firebase'
import Home from '../Routes/home'
import { getDocs, query, where, collection } from 'firebase/firestore'


const NavbarAdmin = () => {
  const [isAuth, setIsAuth] = useState(false)
  const [user, setUser] = useState()
  const [wallet, setWallet] = useState('')

  useEffect(() =>
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsAuth(true)
        setUser(user)
        console.log(user);
        const q = query(
          collection(db, "userProfile"),
          where("emailId", "==", auth.currentUser.email)
        );
        const querySnapshot = await getDocs(q);
        const ownProfileDoc = querySnapshot.docs[0]; // Assuming the query returns one document
        const ownProfileData = ownProfileDoc?.data();
        console.log(ownProfileData);
        setWallet(parseFloat(ownProfileData?.wallet))
      } else {
        setIsAuth(false)
      }
    }))
  return (
    <div>
      <header>
        <div className="logo-section">Kolkata Fatafat</div>
        <div className="next-sec-logo">
          {isAuth ? (
            <div className='btnsNavbar'>
              <button className='userNameDisplay'>{user.displayName}</button>
              <button className='userNameDisplay'>{wallet}</button>
            </div>
          ) : <div className='divvStyleLR'>
            <Link to="/login"><button className='loginDisplay'>Login</button></Link>
            <Link to="/register"><button className='signupDisplay'>Register</button></Link>
          </div>}
        </div>
      </header>
      <div className="links-tabs">
        <ul className='scrollable-ul'>
          <li className="liinks-tab-child"><Link to='/'>Home</Link></li>
          <li className="border-only--li"></li>
          <li className="liinks-tab-child"><Link to="/admin/game">Game</Link></li>
          <li className="border-only--li"></li>
          <li className="liinks-tab-child"><Link to="/admin/users">Users</Link></li>
          <li className="border-only--li"></li>
          <li className="liinks-tab-child"><Link to="/admin/market-analysis">Market Analysis</Link></li>
          <li className="border-only--li"></li>
          <li className="liinks-tab-child"><Link to="/admin/profit-loss">Profit / Loss</Link></li>
          <li className="border-only--li"></li>
          <li className="liinks-tab-child"><Link to="/admin/fund-req">Fund</Link></li>
          <li className="border-only--li"></li>

          <li className="liinks-tab-child"><Link to='/admin/bet-history'>Bet Log</Link></li>
          <li className="border-only--li"></li>
          <li className="liinks-tab-child"><Link to='/admin/withdraw-request'>Withdraw Request</Link></li>
          <li className="border-only--li"></li>
          <li className="liinks-tab-child"><Link to='/admin/withdraw-history'>Withdraw History</Link></li>
          <li className="border-only--li"></li>
          <li className="liinks-tab-child"><Link to='/admin/fund-add-history'>Fund Add History</Link></li>
          {/* <li className="border-only--li"></li> */}
          {/* <li className="liinks-tab-child"><Link to='/user-log'>User Log</Link></li> */}
          {/* <li className="liinks-tab-child"><Link>Password</Link></li> */}
          <li className="border-only--li"></li>
          <li className="liinks-tab-child"><div onClick={() => {
            signOut(auth).then(() => {
            }).catch((error) => {
            });
          }}>Logout</div></li>
        </ul>
      </div>
      <Outlet />
    </div >
  )
}

export default NavbarAdmin
