/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-key */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable quotes */import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where, deleteDoc, doc, updateDoc, getDoc } from 'firebase/firestore';
import { db, auth } from '../../utils/firebase';

const BetHistoryMaster = () => {
    const [betHistory, setBetHistory] = useState([]);
    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const [emailFilter, setEmailFilter] = useState('');
    const [dateFilter, setDateFilter] = useState('');
    const [loading, setLoading] = useState(true);
    const [todayDate, setTodayDate] = useState('');

    useEffect(() => {
        if (auth.currentUser) {
            const today = new Date();
            const formattedToday = formatDate(today);
            setTodayDate(formattedToday); // Set today's date in state
            fetchBetHistory(formattedToday);
        }
    }, [emailFilter, dateFilter]);

    const fetchBetHistory = async (todayDate) => {
        setLoading(true);
        try {
            let q = collection(db, 'bets');
            const filters = [];

            if (emailFilter) {
                filters.push(
                    where('user', '>=', emailFilter.toLowerCase()),
                    where('user', '<=', emailFilter.toLowerCase() + '\uf8ff')
                );
            }

            if (dateFilter) {
                const formattedDateFilter = formatDate(dateFilter);
                filters.push(where('date', '==', formattedDateFilter));
            } else {
                // Filter by today's date if dateFilter is not specified
                filters.push(where('date', '==', todayDate));
            }

            if (filters.length > 0) {
                q = query(q, ...filters);
            }

            const querySnapshot = await getDocs(q);
            const betHistoryData = [];

            const promises = querySnapshot.docs.map(async (betDoc) => {
                const betData = betDoc.data();

                // Check if betData.user is defined
                if (betData.user) {
                    const userQuery = query(
                        collection(db, 'userProfile'),
                        where('emailId', '==', betData.user),
                        where('adminEmail', '==', auth.currentUser.email)
                    );

                    const userSnapshot = await getDocs(userQuery);

                    userSnapshot.forEach(userDoc => {
                        betHistoryData.push({
                            id: betDoc.id,
                            date: betData.date,
                            time: convertTo24Hour(betData.time), // Convert time to 24-hour format
                            number: betData.number,
                            amount: betData.amount,
                            email: betData.user,
                            uid: betData.uid,
                            gameName: betData.gameName
                        });
                    });
                }
            });

            await Promise.all(promises);
            // Sort bet history based on initial sorting criteria
            sortBetHistory(betHistoryData, sortBy, sortOrder);
        } catch (error) {
            console.error('Error fetching bet history:', error);
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (date) => {
        const d = new Date(date);
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const day = d.getDate().toString().padStart(2, '0');
        const year = d.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const convertTo24Hour = (time12h) => {
        const [time, period] = time12h.split(' ');
        let [hours, minutes] = time.split(':');
        hours = (period === 'PM' ? parseInt(hours, 10) + 12 : parseInt(hours, 10)).toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    const handleCancelBet = async (betId, email, amount) => {
        // Implement your cancellation logic here
    };

    const handleSort = (sortByField) => {
        const newSortOrder = sortBy === sortByField && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortBy(sortByField);
        setSortOrder(newSortOrder);

        sortBetHistory(betHistory, sortByField, newSortOrder);
    };

    const sortBetHistory = (history, sortByField, order) => {
        const sortedHistory = [...history].sort((a, b) => {
            if (sortByField === 'email') {
                return order === 'asc' ? a.email.localeCompare(b.email) : b.email.localeCompare(a.email);
            } else if (sortByField === 'date') {
                return order === 'asc' ? new Date(a.date) - new Date(b.date) : new Date(b.date) - new Date(a.date);
            } else if (sortByField === 'time') {
                return order === 'asc' ? a.time.localeCompare(b.time) : b.time.localeCompare(a.time);
            }
            return 0;
        });

        setBetHistory(sortedHistory);
    };

    if (loading) {
        return <div>Loading Data...</div>;
    }

    return (
        <div>
            <div className="inputSort-bethistory">
                <input
                    type="text"
                    placeholder="Enter Email"
                    value={emailFilter}
                    onChange={(e) => setEmailFilter(e.target.value)}
                />
                <input
                    type="date"
                    placeholder="Select Date"
                    value={dateFilter}
                    onChange={(e) => setDateFilter(e.target.value)}
                />
            </div>
            <div className="table-wrapper">
                <table className="fl-table">
                    <thead>
                        <tr>
                            <th onClick={() => handleSort('date')}>Date</th>
                            <th onClick={() => handleSort('time')}>Time</th>
                            <th onClick={() => handleSort('number')}>Number</th>
                            <th onClick={() => handleSort('amount')}>Amount</th>
                            <th onClick={() => handleSort('email')}>Email ID</th>
                            <th onClick={() => handleSort('gameName')}>Game Name</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {betHistory.map(bet => (
                            <tr key={bet.id}>
                                <td>{bet.date}</td>
                                <td>{bet.time}</td>
                                <td>{bet.number}</td>
                                <td>{bet.amount}</td>
                                <td>{bet.email}</td>
                                <td>Bazi {bet.gameName}</td>
                                <td>
                                    <button onClick={() => handleCancelBet(bet.id, bet.email, bet.amount)}>Cancel Bet</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default BetHistoryMaster;
