/* eslint-disable linebreak-style */
import React, { useEffect, useState } from "react";
import { db } from "../utils/firebase";
import { collection, getDocs } from "firebase/firestore";

const UserLog = () => {
  const [userProfiles, setUserProfiles] = useState([]);

  useEffect(() => {
    const fetchUserProfiles = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "userProfile"));
        const userLogArr = [];

        querySnapshot.forEach(doc => {
          const userData = doc.data();

          userData.id = doc.id;
          userLogArr.push(userData);
        });

        setUserProfiles(userLogArr);
        console.log(userLogArr);
      } catch (error) {
        console.error("Error fetching user profiles: ", error);
      }
    };

    fetchUserProfiles();

    // Clean up function to unsubscribe from Firestore listener
    return () => {
      // Any cleanup code if needed
    };
  }, []); // Empty dependency array means it will only run once on component mount

  console.log("Rendering UserLog component"); // Log to check if the component is rendering

  return (
    <div>
      {/* Render user profiles here */}
      {userProfiles.map(profile => (
        <div key={profile.id}>
          {/* Render each user profile data */}
          <p>User ID: {profile.id}</p>
          {/* Render other profile data as needed */}
          {/* You can access other fields like profile.name, profile.email, etc. */}
        </div>
      ))}
    </div>
  );
};

export default UserLog;
